export class EmptyFilter {
    constructor() { }

    toString() {
        return `()`;
    }

    toSQL(cb) {
        return ``
    }

    matches(target) {
        return true;
    }

    has(attribute) {
        return false;
    }

    addFilter(filter) {
    }
}

export class AndFilter {
    constructor(filters = []) {
        this.filters = filters.slice(0);
    }

    toString() {
        return `(&${this.filters.map(f => f.toString()).join("")})`;
    }

    toSQL(cb) {
        return `(${this.filters.map(f => f.toSQL(cb)).join(" AND ")})`
    }

    matches(target) {
        if (this.filters.length == 0)
            return true;
        for (let filter of this.filters)
            if (!filter.matches(target))
                return false;
        return true;
    }

    has(attribute) {
        if (this.filters.length == 0)
            return false;
        for (let filter of this.filters)
            if (filter.has(attribute))
                return true;
        return false;
    }

    addFilter(filter) {
        this.filters.push(filter);
    }
}

export class OrFilter {
    constructor(filters = []) {
        this.filters = filters.slice();
    }

    toString() {
        return `(|${this.filters.map(f => f.toString()).join("")})`;
    }

    toSQL(cb) {
        return `(${this.filters.map(f => f.toSQL(cb)).join(" OR ")})`
    }

    matches(target) {
        if (this.filters.length == 0)
            return true;
        for (let filter of this.filters)
            if (filter.matches(target))
                return true;
        return false;
    }

    has(attribute) {
        if (this.filters.length == 0)
            return false;
        for (let filter of this.filters)
            if (filter.has(attribute))
                return true;
        return false;
    }

    addFilter(filter) {
        this.filters.push(filter);
    }
}

export class NotFilter {
    constructor(filter) {
        this.filters = [filter];
    }

    toString() {
        return `(!${this.filters[0].toString()})`;
    }

    toSQL(cb) {
        if (this.filters[0] instanceof PresenceFilter) {
            return cb(this.filters[0].attribute, "NULL", "IS");
        } else {
            return `NOT ${this.filters[0].toSQL(cb)}`
        }
    }

    matches(target) {
        if (this.filters.length == 0)
            return true;
        for (let filter of this.filters)
            if (!filter.matches(target))
                return true;
        return false;
    }

    has(attribute) {
        if (this.filters.length == 0)
            return false;
        for (let filter of this.filters)
            if (filter.has(attribute))
                return true;
        return false;
    }
}

export class PresenceFilter {
    constructor(attribute) {
        this.attribute = attribute;
    }

    toString() {
        return `(${encode(this.attribute)}=*)`;
    }

    toSQL(cb) {
        return cb(this.attribute, "NULL", "IS NOT");
    }

    matches(target) {
        let value = getAttributeValue(target, this.attribute);
        return value != undefined && value != null;
    }

    has(attribute) {
        return this.attribute == attribute;
    }
}

export class SubstringFilter {
    constructor(attribute, value) {
        this.attribute = attribute;
        this.value = value;
        let parts = value.split("*");
        if (parts.length == 0)
            throw new Error("wildcard missing");
        this.initial = parts.shift();
        this.final = parts.pop();
        this.any = parts;
    }

    toString() {
        return `(${encode(this.attribute)}=${encode(this.initial)}*${this.any.map(a => encode(a)).join("*")}${this.any.length > 0 ? "*" : ""}${encode(this.final)})`;
    }

    toSQL(cb) {
        return cb(this.attribute, `${this.initial}%${this.any.join("%")}${this.any.length > 0 ? "%" : ""}${this.final}`, "LIKE");
    }

    matches(target) {
        let value = getAttributeValue(target, this.attribute);
        if (value != undefined && value != null) {
            let expr = new RegExp(`^${this.initial}.*${this.any.join(".*")}.*${this.final}$`);
            return expr.test(value.toString());
        }
        return false;
    }

    has(attribute) {
        return this.attribute == attribute;
    }
}

export class EqualityFilter {
    constructor(attribute, value) {
        this.attribute = attribute;
        this.value = value;
    }

    toString() {
        return `(${encode(this.attribute)}=${encode(this.value)})`;
    }

    toSQL(cb) {
        if (this.value?.toString()?.toLowerCase() === "null") {
            return cb(this.attribute, "NULL", "IS");
        } else {
            return cb(this.attribute, this.value, "=");
        }
    }

    matches(target) {
        let value = getAttributeValue(target, this.attribute);
        return value?.toString() == this.value?.toString();
    }

    has(attribute) {
        return this.attribute == attribute;
    }
}

export class GreaterThanEqualsFilter {
    constructor(attribute, value) {
        this.attribute = attribute;
        this.value = value;
    }

    toString() {
        return `(${encode(this.attribute)}>=${encode(this.value)})`;
    }

    toSQL(cb) {
        return cb(this.attribute, this.value, ">=");
    }

    matches(target) {
        let value = getAttributeValue(target, this.attribute);
        return this.value <= value;
    }

    has(attribute) {
        return this.attribute == attribute;
    }
}

export class LessThanEqualsFilter {
    constructor(attribute, value) {
        this.attribute = attribute;
        this.value = value;
    }

    toString() {
        return `(${encode(this.attribute)}>=${encode(this.value)})`;
    }

    toSQL(cb) {
        return cb(this.attribute, this.value, "<=");
    }

    matches(target) {
        let value = getAttributeValue(target, this.attribute);
        return this.value >= value;
    }

    has(attribute) {
        return this.attribute == attribute;
    }
}

const attributeRegExp = /^[-_\.a-zA-Z0-9]+/;
const hexRegExp = /^[a-fA-F0-9]{2}$/;

function encode(str = "") {
    let esc = "";
    for (let i = 0; i < str.length; i++) {
        switch (str[i]) {
        case '*':
            esc += '\\2a';
            break;
        case '(':
            esc += '\\28';
            break;
        case ')':
            esc += '\\29';
            break;
        case '\\':
            esc += '\\5c';
            break;
        case '\0':
            esc += '\\00';
            break;
        default:
            esc += str[i];
            break;
        }
    }
    return esc;
}

function decode(str) {
    var cur = 0;
    var len = str.length;
    var out = '';
    while (cur < len) {
        var c = str[cur];
        switch (c) {
            case '(':
                throw new Error('illegal unescaped char: ' + c);
            case '\\':
                var val = str.substr(cur + 1, 2);
                if (val.match(hexRegExp) === null)
                    throw new Error('invalid escaped char');
                out += String.fromCharCode(parseInt(val, 16));
                cur += 3;
                break;
            default:
                out += c;
                cur++;
                break;
        }
    }
    return out;
}

function getAttributeValue(target, attribute) {
    let parts = attribute.split(".");
    if (parts.length == 1)
        return target[parts[0]];
    else
        return getAttributeValue(target[parts[0]], parts.slice(1).join("."));
}

function parseExpression(str) {
    var attr, match, remain;
    if (str[0] === ':') {
        /*
        * An extensible filter can have no attribute name.
        * (Only valid when using dn and * matching-rule evaluation)
        */
        attr = '';
        remain = str;
    } else if ((match = str.match(attributeRegExp)) !== null) {
        attr = match[0];
        remain = str.substr(attr.length);
    } else {
        throw new Error("invalid attribute name");
    }
    
    if (remain === '=*') {
        return new PresenceFilter(attr);
    } else if (remain[0] === '=') {
        remain = remain.substr(1);
        if (remain.indexOf('*') !== -1) {
            return new SubstringFilter(attr, remain.split("*").map(decode).join("*"));
        } else {
            return new EqualityFilter(attr, decode(remain));
        }
    } else if (remain[0] === '>' && remain[1] === '=') {
        return new GreaterThanEqualsFilter(attr,decode(remain.substr(2)));
    } else if (remain[0] === '<' && remain[1] === '=') {
        return new LessThanEqualsFilter(attr, decode(remain.substr(2)));
    }
    throw new Error('invalid expression');
}

function parseGroup(str, pos) {
    var output;

    if (str.charAt(pos) != "(")
        throw new Error("missing parens");
    pos++;
    if (str.charAt(pos) == '&') {
        pos++;
        var children = [];
        do {
            let result = parseGroup(str, pos);
            children.push(result.filter);
            pos = result.end + 1;
        } while (pos < str.length && str.charAt(pos) != ")");
        output = new AndFilter(children);
    } else if (str.charAt(pos) == "|") {
        pos++;
        var children = [];
        do {
            let result = parseGroup(str, pos);
            children.push(result.filter);
            pos = result.end + 1;
        } while (pos < str.length && str.charAt(pos) != ")");
        output = new OrFilter(children);
    } else if (str.charAt(pos) == "!") {
        let result = parseGroup(str, pos + 1);
        output = new NotFilter(result.filter);
        pos = result.end + 1;
        if (str[pos] != ")")
            throw new Error("unbalanced parens");
    } else {
        let end = str.indexOf(')', pos);
        if (end == -1)
            throw new Error("unbalanced parens");
        output = parseExpression(str.substr(pos, end - pos));
        pos = end;
    }
    if (pos >= str.length)
        throw new Error("unbalanced parens");
    return {
        end: pos,
        filter: output
    };
}

export function parseFilter(str) {
    if (typeof str !== "string")
        throw new Error("input must be string");
    if (!str)
        return new EmptyFilter(); //throw new Error("input string cannot be empty");
    if (str.charAt(0) != "(")
        str = "(" + str + ")";
    let result = parseGroup(str, 0);
    if (result.end < (str.length - 1))
        throw new Error("input string is unbalanced");
    return result.filter;
}
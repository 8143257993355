import { LZSPARouter } from "@eventbuilder-utils/lzspa";
import EBPubSubClient from "@eventbuilder-utils/pubsub-client";
import "external-svg-loader";

import { EBSession } from "./utils/session";
import "./scss/styles.scss";

import "./elements/dropdown-button.js";
import "./elements/datetime-picker.js";
import { Modal } from "./modals/index.js";

import "./elements/ebswitch.js";
import { TenantSelectModal } from "./modals/tenant-select.js";

var activeAPICalls = 0;

function setColorScheme(mode) {
    document.body.setAttribute("data-theme", mode);
    window.localStorage.setItem("scheme", mode);
    document.querySelector("#darkModeToggle").setAttribute("aria-checked", (mode === "dark").toString());
    document.body.dispatchEvent(new CustomEvent("ebthemechanged"));
}

if (window.localStorage.getItem("scheme")) {
    setColorScheme(window.localStorage.getItem("scheme"));
} else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    setColorScheme("dark");
} else {
    setColorScheme("light");
}

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => setColorScheme(event.matches ? "dark" : "light"));

document.querySelector("#darkModeToggle").addEventListener("click", (event) => {
    setColorScheme(document.body.getAttribute("data-theme") === "dark" ? "light" : "dark");
});

//document.querySelectorAll(".topbar > .topbar__content > button[aria-controls]").forEach(element => {
//    element.addEventListener("click", (event) => {
//        const element = document.getElementById(event.target.getAttribute("aria-controls"));
//        element.style.display = element.style.display == "block" ? "none" : "block";
//    });
//});

document.querySelector("#userMenuMyAccountLink").addEventListener("click", async (ev) => {
    document.querySelector("#user-menu").classList.toggle("hide");
    LZSPARouter.navigate(`/accounts/${EBSession.account.id}`);
});

document.querySelector("#userMenuSwitchTenantLink").addEventListener("click", async (ev) => {
    document.querySelector("#user-menu").classList.toggle("hide");
    const result = await TenantSelectModal();
    if (!!!result) return;
    await EBSession.changeTenant(result);
});

document.querySelector("button.js__user-menu-btn").addEventListener("click", (e) => {
    const el = document.querySelector("#user-menu");
    el.classList.toggle("hide");
    el.style.setProperty("transform", `translate3d(${window.innerWidth - el.clientWidth}px,${document.querySelector("div.js__app-title-bar").clientHeight}px,0px)`);
})

document.addEventListener("mousemove", (event) => { EBSession.resetInactivityTimer(); }, { capture: true, passive: true });
document.addEventListener("mousedown", (event) => { EBSession.resetInactivityTimer(); return true; }, { capture: true, passive: true });
document.addEventListener("touchstart", (event) => { EBSession.resetInactivityTimer(); return true; }, { capture: true, passive: true });
document.addEventListener("click", (event) => { EBSession.resetInactivityTimer(); return true; }, { capture: true, passive: true });
document.addEventListener("keypress", (event) => { EBSession.resetInactivityTimer(); return true; }, { capture: true, passive: true }); 

document.addEventListener("click", (event) => {
    //Auto-hide more menu in tinymce
    for (let el of document.querySelectorAll("div.tox-toolbar__overflow")) {
        if (el.contains(event.target) == false) document.querySelector(`button[aria-controls="${el.parentElement.id}"]`).click();
    }

    if (
        document.querySelector("#notification-menu").classList.contains("hide") &&
        document.querySelector("#user-menu").classList.contains("hide")
    ) return;
    if (
        event.target.closest("#notification-menu") != null ||
        event.target.closest("#user-menu") != null ||
        event.target.closest(".js__app-title-bar") != null
    ) return;
    document.querySelector("#notification-menu").classList.add("hide");
    document.querySelector("#user-menu").classList.add("hide");
});

document.addEventListener('focusin', function (e) { 
    if (e.target.closest('.tox-tinymce-aux, .moxman_window, .tam-assetmanager-root') !== null) { e.stopImmediatePropagation(); } 
    if (e.relatedTarget?.classList?.contains("wysiwyg")) { e.stopImmediatePropagation(); }
});

EBPubSubClient.on("message", (group, message) => {
    console.log(`${group} => ${message}`);
});

EBSession
    .on("eb:session.requeststart", () => {
        activeAPICalls++;
        document.getElementById("loadingSpinner").style.visibility = (activeAPICalls > 0 ? "visible" : "hidden");
    })
    .on("eb:session.requestend", () => {
        activeAPICalls--;
        document.getElementById("loadingSpinner").style.visibility = (activeAPICalls > 0 ? "visible" : "hidden");
    })
    .on("eb:session.tenantchange", () => {
        document.querySelector("div.js__session-tenant-name").innerText = EBSession.tenant.name;

        for (let feature of ["accounts","events","pages","registrants","certificates","templates","tenants"]) {
            document.body.querySelector(`.js__app-navigation > a[href="/${feature.toLowerCase()}"]`)?.style?.setProperty("display", EBSession.tenant.features[feature] ? null : "none");
        }

        if (EBSession.features.platformAdministration) {
            for (let feature of ["accounts","registrants","tasks","subscriptions"]) {
                document.body.querySelector(`.js__app-navigation > a[href="/${feature.toLowerCase()}"]`)?.style?.setProperty("display", null);
            }
        }
        /*if (EBSession.tenant.permissions.platformAccountsRead)
            document.body.querySelector(`.js__app-navigation > a[href="/accounts"]`)?.style?.removeProperty("display");
        else
            document.body.querySelector(`.js__app-navigation > a[href="/accounts"]`)?.style?.setProperty("display", "none");
        
        if (EBSession.tenant.permissions.platformTasksRead)
            document.body.querySelector(`.js__app-navigation > a[href="/tasks"]`)?.style?.removeProperty("display");
        else
            document.body.querySelector(`.js__app-navigation > a[href="/tasks"]`)?.style?.setProperty("display", "none");

        if (EBSession.tenant.permissions.platformRegistrantsRead)
            document.body.querySelector(`.js__app-navigation > a[href="/registrants"]`)?.style?.removeProperty("display");
        else
            document.body.querySelector(`.js__app-navigation > a[href="/registrants"]`)?.style?.setProperty("display", "none");

        if (EBSession.tenant.permissions.platformSubscriptionsRead)
            document.body.querySelector(`.js__app-navigation > a[href="/subscriptions"]`)?.style?.removeProperty("display");
        else
            document.body.querySelector(`.js__app-navigation > a[href="/subscriptions"]`)?.style?.setProperty("display", "none");*/

        LZSPARouter.activeComponent?.reload();
    })
    .on("eb:session.login", () => {
        document.querySelector("div.js__session-account-name").innerText = `${EBSession.account.firstName} ${EBSession.account.lastName}`;
        document.querySelector("div.js__session-account-email").innerText = EBSession.account.email;
        //document.getElementById("userMenuMyAccountLink").href = `/accounts/${EBSession.account.id}`;
        //document.getElementById("userMenuEmailText").innerText = EBSession.account.email;
        //document.getElementById("indexPortalName").innerText = Session.tenant.name;

    })
    .on("eb:session.logout", () => {
        document.querySelector("div.js__session-account-name").innerText = "";
        document.querySelector("div.js__session-account-email").innerText = "";
        //document.getElementById("userMenuEmailText").innerText = "";
        //document.getElementById("indexPortalName").innerText = "";

        for (let feature of ["accounts","events","pages","registrants","certificates","templates","tenants"]) {
            document.body.querySelector(`.js__app-navigation > a[href="/${feature.toLowerCase()}"]`)?.style?.setProperty("display", "none");
        }
        for (let feature of ["accounts","registrants","tasks","subscriptions"]) {
            document.body.querySelector(`.js__app-navigation > a[href="/${feature.toLowerCase()}"]`)?.style?.setProperty("display", "none");
        }

        if (new URL(window.location.href).pathname.startsWith("/login") == false) LZSPARouter.navigate(`/login?redirect=${encodeURIComponent(new URL(window.location.href).pathname)}`);
    })
    .on("eb:session.initialized", () => {
        LZSPARouter
            .on("lzspa:router.start", (event) => {
                //console.log("testing");
            })
            .on("lzspa:router.end", (event) => {
                document.getElementById("loadingSpinner").style.visibility = (activeAPICalls > 0 ? "visible" : "hidden");

                // Set active navigation item
                document.querySelector(".js__app-navigation a.active")?.classList?.remove("active");
                document.querySelector(`.js__app-navigation a[href="${"/" + (new URL(window.location.href).pathname.split("/")[1] || "")}"]`)?.classList?.add("active");
            })
            .on("lzspa:router.notfound", (event) => {
                LZSPARouter.navigate("/");
            })
            .on("lzspa:router.accessdenied", (event) => {
                if (!EBSession.valid)
                    LZSPARouter.navigate("/login?redirect=" + encodeURIComponent(event.detail.url));
                else
                    LZSPARouter.navigate("/");
            })
            .initialize({
                routes: [
                    { path: "/login",                                   component: () => import("./components/login") },
                    { path: "/forgot",                                  component: () => import("./components/forgot-password") },
                    { path: "/dashboard",                               component: () => import("./components/dashboard"),                      canActivate: () => EBSession.valid },
                    { path: "/accounts",                                component: () => import("./components/account-list"),                   canActivate: () => EBSession.valid },
                    { path: "/accounts/:account",                       component: () => import("./components/account-detail"),                 canActivate: () => EBSession.valid },
                    { path: "/events",                                  component: () => import("./components/event-list"),                     canActivate: () => EBSession.valid },
                    { path: "/events/create",                           component: () => import("./components/event-create"),                   canActivate: () => EBSession.valid },
                    { path: "/events/:event/console",                   component: () => import("./components/event-console"),                  canActivate: () => EBSession.valid },
                    { path: "/events/:event",                           component: () => import("./components/event-detail"),                   canActivate: () => EBSession.valid },
                    { path: "/registrants",                             component: () => import("./components/registrant-list"),                canActivate: () => EBSession.valid },
                    { path: "/registrants/:registrant",                 component: () => import("./components/registrant-detail"),              canActivate: () => EBSession.valid },
                    { path: "/pages",                                   component: () => import("./components/page-list"),                      canActivate: () => EBSession.valid },
                    { path: "/pages/:page",                             component: () => import("./components/page-detail"),                    canActivate: () => EBSession.valid },
                    { path: "/certificates",                            component: () => import("./components/certificate-list"),               canActivate: () => EBSession.valid },
                    { path: "/certificates/create",                     component: () => import("./components/certificate-create"),             canActivate: () => EBSession.valid },
                    { path: "/certificates/:event",                     component: () => import("./components/certificate-detail"),             canActivate: () => EBSession.valid },
                    { path: "/subscriptions",                           component: () => import("./components/subscription-list"),              canActivate: () => EBSession.valid },
                    { path: "/subscriptions/:subscription",             component: () => import("./components/subscription-detail"),            canActivate: () => EBSession.valid },
                    { path: "/tags",                                    component: () => import("./components/tag-list"),                       canActivate: () => EBSession.valid },
                    { path: "/tasks",                                   component: () => import("./components/task-list"),                      canActivate: () => EBSession.valid },
                    { path: "/templates",                               component: () => import("./components/template-list"),                  canActivate: () => EBSession.valid },
                    { path: "/templates/create",                        component: () => import("./components/template-create"),                canActivate: () => EBSession.valid },
                    { path: "/templates/:template",                     component: () => import("./components/template-detail"),                canActivate: () => EBSession.valid },
                    { path: "/tenants",                                 component: () => import("./components/tenant-list"),                    canActivate: () => EBSession.valid },
                    { path: "/tenants/create",                          component: () => import("./components/tenant-create"),                  canActivate: () => EBSession.valid },
                    { path: "/tenants/:tenant",                         component: () => import("./components/tenant-detail"),                  canActivate: () => EBSession.valid },
                    { path: "/",                                        component: () => import("./components/dashboard"),                      canActivate: () => EBSession.valid },
                ],
                element: document.body.querySelector("#app > div.app__content"),
                globals: {
                    session: EBSession
                },
                debug: true
            });
    })
    .initialize();
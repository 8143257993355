import handlebars from "handlebars/dist/cjs/handlebars.js";

const LZSPACompile = handlebars.compile;

function debounce(callback, timeout = 300){
    var timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { callback.apply(this, args); }, timeout);
    };
}

function debounce_leading(func, timeout = 300){
    let timer;
    return (...args) => {
        if (!timer) { func.apply(this, args); }
        clearTimeout(timer);
        timer = setTimeout(() => { timer = undefined; }, timeout);
    };
}

function LZSPAObserver(target, handler) {
	// An array of all the objects that we have assigned Proxies to
	var targets = [];

	// An array of arrays containing the Proxies created for each target object. targetsProxy is index-matched with
	// 'targets' -- together, the pair offer a Hash table where the key is not a string nor number, but the actual target object
	var targetsProxy = [];

	// this variable tracks duplicate proxies assigned to the same target.
	// the 'set' handler below will trigger the same change on all other Proxies tracking the same target.
	// however, in order to avoid an infinite loop of Proxies triggering and re-triggering one another, we use dupProxy
	// to track that a given Proxy was modified from the 'set' handler
	var dupProxy = null;

	var _getProperty = function(obj, path) {
		return path.split('.').reduce(function(prev, curr) {
			return prev ? prev[curr] : undefined
		}, obj || self)
	};

	/**
	 * Create a new ES6 `Proxy` whose changes we can observe through the `observe()` method.
	 * @param {object} target Plain object that we want to observe for changes.
	 * @param {function(ObservableSlimChange[])} [observer] Function that will be invoked when a change is made to the proxy of `target`.
	 * When invoked, this function is passed a single argument: an array of `ObservableSlimChange` detailing each change that has been made.
	 * @param {object} originalObservable The original observable created by the user, exists for recursion purposes, allows one observable to observe
	 * change on any nested/child objects.
	 * @param {{target: object, property: string}[]} originalPath Array of objects, each object having the properties `target` and `property`:
	 * `target` is referring to the observed object itself and `property` referring to the name of that object in the nested structure.
	 * The path of the property in relation to the target on the original observable, exists for recursion purposes, allows one observable to observe
	 * change on any nested/child objects.
	 * @returns {ProxyConstructor} Proxy of the target object.
	 */
	var _create = function(target, originalObservable, originalPath) {

		var observable = originalObservable || null;

		// record the nested path taken to access this object -- if there was no path then we provide the first empty entry
		var path = originalPath || [{"target":target,"property":"this"}];

		// in order to accurately report the "previous value" of the "length" property on an Array
		// we must use a helper property because intercepting a length change is not always possible as of 8/13/2018 in
		// Chrome -- the new `length` value is already set by the time the `set` handler is invoked
		if (target instanceof Array) {
			if (!target.hasOwnProperty("__length"))
				Object.defineProperty(target, "__length", { enumerable: false, value: target.length, writable: true });
			else
				target.__length = target.length;
		}

		var changes = [];

		/**
		 * Returns a string of the nested path (in relation to the top-level observed object) of the property being modified or deleted.
		 * @param {object} target Plain object that we want to observe for changes.
		 * @param {string} property Property name.
		 * @param {boolean} [jsonPointer] Set to `true` if the string path should be formatted as a JSON pointer rather than with the dot notation
		 * (`false` as default).
		 * @returns {string} Nested path (e.g., `hello.testing.1.bar` or, if JSON pointer, `/hello/testing/1/bar`).
		 */
		var _getPath = function(target, property, jsonPointer) {

			var fullPath = "";
			var lastTarget = null;

			// loop over each item in the path and append it to full path
			for (var i = 0; i < path.length; i++) {

				// if the current object was a member of an array, it's possible that the array was at one point
				// mutated and would cause the position of the current object in that array to change. we perform an indexOf
				// lookup here to determine the current position of that object in the array before we add it to fullPath
				if (lastTarget instanceof Array && !isNaN(path[i].property)) {
					path[i].property = lastTarget.indexOf(path[i].target);
				}

				fullPath = fullPath + "." + path[i].property
				lastTarget = path[i].target;
			}

			// add the current property
			fullPath = fullPath + "." + property;

			// remove the beginning two dots -- ..foo.bar becomes foo.bar (the first item in the nested chain doesn't have a property name)
			fullPath = fullPath.substring(1);

			if (jsonPointer === true) fullPath = "/" + fullPath.replace(/\./g, "/");

			return fullPath;
		};

		var _notifyObservers = function(numChanges) {
            var changesCopy = changes.slice(0);
            changes = [];
            for (var i = 0; i < observable.observers.length; i++) {
                if (observable.observers[i].path == "this") {
                    observable.observers[i].callback(changesCopy.map(change => ({
                        currentPath: change.currentPath,
                        newValue: change.newValue,
                        previousValue: change.previousValue,
                        property: change.property,
                        target: change.target,
                        type: change.type
                    })));
                } else {
                    var observerChanges = changesCopy.filter(change => change.currentPath.startsWith(`${observable.observers[i].path}.`));
                    if (observerChanges.length != 0) observable.observers[i].callback(observerChanges.map(change => ({
                        currentPath: change.currentPath.substring(observable.observers[i].path.length + 1),
                        newValue: change.newValue,
                        previousValue: change.previousValue,
                        property: change.property,
                        target: change.target,
                        type: change.type
                    })));
                }
            }
		};

		var handler = {
			get: function(target, property) {
				if (property === "__getTarget") {
                    return target;
                }

				if (property === "__isProxy") {
                    return true;
                }

				if (property === "__getParent") {
                    return function(i) {
						if (typeof i === "undefined") var i = 1;
						var parentPath = _getPath(target, "__getParent").split(".");
						parentPath.splice(-(i+1),(i+1));
						return _getProperty(observable.parentProxy, parentPath.join("."));
					}
                }

				if (property === "__getPath") {
                    var parentPath = _getPath(target, "__getParent");
                    return parentPath.slice(0, -12);
                }

                if (property === "lzAddObserver") {
                    return function(callback) {
                        var parentPath = _getPath(target, "__getParent");
                        observable.observers.push({callback:callback, path:parentPath.slice(0, -12) || ""});
                    }
                }

                var targetProp = target[property];
				if (target instanceof Date && targetProp instanceof Function && targetProp !== null) {
					return targetProp.bind(target);
				}

				// if we are traversing into a new object, then we want to record path to that object and return a new observable.
				// recursively returning a new observable allows us a single Observable.observe() to monitor all changes on
				// the target object and any objects nested within.
				if (targetProp instanceof Object && targetProp !== null && target.hasOwnProperty(property)) {

					// if we've found a proxy nested on the object, then we want to retrieve the original object behind that proxy
					if (targetProp.__isProxy === true) targetProp = targetProp.__getTarget;

					// if the object accessed by the user (targetProp) already has a __targetPosition AND the object
					// stored at target[targetProp.__targetPosition] is not null, then that means we are already observing this object
					// we might be able to return a proxy that we've already created for the object
					if (targetProp.__targetPosition > -1 && targets[targetProp.__targetPosition] !== null) {

						// loop over the proxies that we've created for this object
						var ttp = targetsProxy[targetProp.__targetPosition];
						for (var i = 0, l = ttp.length; i < l; i++) {

							// if we find a proxy that was setup for this particular observable, then return that proxy
							if (observable === ttp[i].observable) {
								return ttp[i].proxy;
							}
						}
					}

					// if we're arrived here, then that means there is no proxy for the object the user just accessed, so we
					// have to create a new proxy for it

					// create a shallow copy of the path array -- if we didn't create a shallow copy then all nested objects would share the same path array and the path wouldn't be accurate
					var newPath = path.slice(0);
					newPath.push({"target":targetProp,"property":property});
					return _create(targetProp, observable, newPath);
				} else {
					return targetProp;
				}
			},
 			deleteProperty: function(target, property) {

				// was this change an original change or was it a change that was re-triggered below
				var originalChange = true;
				if (dupProxy === proxy) {
					originalChange = false;
					dupProxy = null;
				}

				// in order to report what the previous value was, we must make a copy of it before it is deleted
				var previousValue = Object.assign({}, target);

				// record the deletion that just took place
				changes.push({
					"type":"delete"
					,"target":target
					,"property":property
					,"newValue":null
					,"previousValue":previousValue[property]
					,"currentPath":_getPath(target, property)
					,"proxy":proxy
				});

				if (originalChange === true) {

					// perform the delete that we've trapped if changes are not paused for this observable
					delete target[property];

					for (var a = 0, l = targets.length; a < l; a++) if (target === targets[a]) break;

					// loop over each proxy and see if the target for this change has any other proxies
					var currentTargetProxy = targetsProxy[a] || [];

					var b = currentTargetProxy.length;
					while (b--) {
						// if the same target has a different proxy
						if (currentTargetProxy[b].proxy !== proxy) {
							// !!IMPORTANT!! store the proxy as a duplicate proxy (dupProxy) -- this will adjust the behavior above appropriately (that is,
							// prevent a change on dupProxy from re-triggering the same change on other proxies)
							dupProxy = currentTargetProxy[b].proxy;

							// make the same delete on the different proxy for the same target object. it is important that we make this change *after* we invoke the same change
							// on any other proxies so that the previousValue can show up correct for the other proxies
							delete currentTargetProxy[b].proxy[property];
						}
					}

				}

				_notifyObservers(changes.length);

				return true;

			},
			set: function(target, property, value, receiver) {

				// if the value we're assigning is an object, then we want to ensure
				// that we're assigning the original object, not the proxy, in order to avoid mixing
				// the actual targets and proxies -- creates issues with path logging if we don't do this
				if (value && value.__isProxy) value = value.__getTarget;

				// improve performance by saving direct references to the property
				var targetProp = target[property];

				// Only record this change if:
				// 	1. the new value differs from the old one
				//	2. OR if this proxy was not the original proxy to receive the change
				// 	3. OR the modified target is an array and the modified property is "length" and our helper property __length indicates that the array length has changed
				//
				// Regarding #3 above: mutations of arrays via .push or .splice actually modify the .length before the set handler is invoked
				// so in order to accurately report the correct previousValue for the .length, we have to use a helper property.
				if (targetProp !== value || (property === "length" && target instanceof Array && target.__length !== value)) {

					var typeOfTargetProp = (typeof targetProp);

					// determine if we're adding something new or modifying some that already existed
					var type = "update";
					if (typeOfTargetProp === "undefined") type = "add";

					// store the change that just occurred. it is important that we store the change before invoking the other proxies so that the previousValue is correct
					changes.push({
						"type":type
						,"target":target
						,"property":property
						,"newValue":value
						,"previousValue":receiver[property]
						,"currentPath":_getPath(target, property)
						,"proxy":proxy
					});

					// mutations of arrays via .push or .splice actually modify the .length before the set handler is invoked
					// so in order to accurately report the correct previousValue for the .length, we have to use a helper property.
					if (property === "length" && target instanceof Array && target.__length !== value) {
						changes[changes.length-1].previousValue = target.__length;
						target.__length = value;
					}

                    // because the value actually differs than the previous value
                    // we need to store the new value on the original target object,
                    // but only as long as changes have not been paused
                    target[property] = value;

                    // if the property being overwritten is an object, then that means this observable
                    // will need to stop monitoring this object and any nested objects underneath the overwritten object else they'll become
                    // orphaned and grow memory usage. we execute this on a setTimeout so that the clean-up process does not block
                    // the UI rendering -- there's no need to execute the clean up immediately
                    setTimeout(function() {

                        if (typeOfTargetProp === "object" && targetProp !== null) {

                            // check if the to-be-overwritten target property still exists on the target object
                            // if it does still exist on the object, then we don't want to stop observing it. this resolves
                            // an issue where array .sort() triggers objects to be overwritten, but instead of being overwritten
                            // and discarded, they are shuffled to a new position in the array
                            var keys = Object.keys(target);
                            for (var i = 0, l = keys.length; i < l; i++) {
                                if (target[keys[i]] === targetProp) return;
                            }

                            var stillExists = false;

                            // now we perform the more expensive search recursively through the target object.
                            // if we find the targetProp (that was just overwritten) still exists somewhere else
                            // further down in the object, then we still need to observe the targetProp on this observable.
                            (function iterate(target) {
                                var keys = Object.keys(target);
                                for (var i = 0, l = keys.length; i < l; i++) {

                                    var property = keys[i];
                                    var nestedTarget = target[property];

                                    if (nestedTarget instanceof Object && nestedTarget !== null) iterate(nestedTarget);
                                    if (nestedTarget === targetProp) {
                                        stillExists = true;
                                        return;
                                    }
                                };
                            })(target);

                            // even though targetProp was overwritten, if it still exists somewhere else on the object,
                            // then we don't want to remove the observable for that object (targetProp)
                            if (stillExists === true) return;

                            // loop over each property and recursively invoke the `iterate` function for any
                            // objects nested on targetProp
                            (function iterate(obj) {
                                var keys = Object.keys(obj);
                                for (var i = 0, l = keys.length; i < l; i++) {
                                    var objProp = obj[keys[i]];
                                    if (objProp instanceof Object && objProp !== null) iterate(objProp);
                                }
                            })(targetProp)
                        }
                    },10000);
						
                    // notify the observer functions that the target has been modified
                    _notifyObservers(changes.length);

				}
				return true;
			}
		}

		// create the proxy that we'll use to observe any changes
		var proxy = new Proxy(target, handler);

		// we don't want to create a new observable if this function was invoked recursively
		if (observable === null) {
			observable = {"parentTarget":target, "parentProxy":proxy, "observers":[], "paused":true, "path":path};
		}

		return proxy;
	};

    var proxy = _create(target);
    if (handler != undefined) proxy.lzAddObserver(handler);

    // recursively loop over all nested objects on the proxy we've just created
    // this will allow the top observable to observe any changes that occur on a nested object
    (function iterate(proxy) {
        var target = proxy.__getTarget;
        var keys  = Object.keys(target);
        for (var i = 0, l = keys.length; i < l; i++) {
            var property = keys[i];
            if (target[property] instanceof Object && target[property] !== null) iterate(proxy[property]);
        }
    })(proxy);

    return proxy;
}

class LZSPAComponent {
    lzTemplate = undefined;
    lzDocumentTitle = undefined;

    constructor(params) {
        const eventListener = new EventTarget();
        const element = document.createElement("div");

        this.addEventListener = function (type, callback = null, options = undefined) { eventListener.addEventListener(type, callback, options); };
        this.removeEventListener = function (type, callback = null, options = undefined) { eventListener.removeEventListener(type, callback, options); };
        this.dispatchEvent = function (event) { return eventListener.dispatchEvent(event); }
        
        this.lzGetElement = function (selectors = undefined) {
            if (!selectors) {
                return element;
            } else {
                return element.querySelector(selectors);
            }
        };

        this.lzGetElements = function (selectors = undefined) {
            if (!selectors) {
                return element;
            } else {
                return [...element.querySelectorAll(selectors)];
            }
        };

        this.lzEval = function (script, args) {
            try {
                return Function(`"use strict"; ${script}`).call(this, args);
            } catch (ex) {
                console.warn(`${script} => ${ex.message}`);
                return undefined;
            }
        }

        this.lzEvalString = function (script) {
            return this.lzEval(`return \`${script}\``)?.toString() || "";
        };

        this.lzEvalBoolean = function (script) {
            return this.lzEval(`return ${script}`)?.toString()?.toLowerCase() === "true";
        };

        this.lzGetValue = function (path) {
            return this.lzEval(`return ${path}`);
            //return Function(`"use strict"; ${path} = arguments[0]; return arguments[0];`).call(this, value);
        };

        this.lzSetValue = function (path, value) {
            return this.lzEval(`${path} = arguments[0]; return arguments[0];`, value);
            //return Function(`"use strict"; ${path} = arguments[0]; return arguments[0];`).call(this, value);
        };

        this.initialize = function(data = undefined) {
            this.lzOnInit(data || params);
            return this;
        }

        return LZSPAObserver(this);
    }
    
    get element() { return this.lzGetElement(); }

    get lzHasInvalidElement() { return this.lzFirstInvalidElement !== null; }

    get lzFirstInvalidElement() { return this.lzGetElement(`input[data-lzproperty][required]:invalid, select[data-lzproperty][required]:invalid, textarea[data-lzproperty][required]:invalid`); }

    lzSetFocusOnFirstInvalidElement() { this.lzFirstInvalidElement.focus(); }

    async lzOnRender(params) {
        const self = this;

        try {
            if (this.lzTemplate) {
                if (typeof this.lzTemplate === "function") {
                    this.element.innerHTML = this.lzTemplate(this);
                } else if (typeof this.lzTemplate === "string") {
                    this.element.innerHTML = handlebars.compile(this.lzTemplate)(this);
                } else {
                    throw new Error("unknown template type");
                }
            }
            if (this.lzDocumentTitle != undefined) {
                document.title = LZSPARouter.getDocumentTitle(this.lzDocumentTitle);
                //document.title = LZSPARouter.documentTitleCallback(this.lzDocumentTitle);
            }
        } catch (ex) {
            console.error(ex);
        }

        self.lzGetElements(`[data-lzoninit]`).forEach(element => {
            try {
                self.lzEval(`const event = arguments[0]; ${element.getAttribute("data-lzoninit")}`, { target: element });
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzproperty]`).forEach(element => {
            try {
                const value = self.lzEval(`return ${element.getAttribute("data-lzproperty")}`)?.toString() || "";
                if (element.tagName == "INPUT") {
                    if (element.type == "text" || element.type == "number" || element.type == "email" || element.type == "password") {
                        element.value = value;
                        element.addEventListener("input", debounce((event) => self.lzSetValue(element.getAttribute("data-lzproperty"), event.target.value)));
                    } else if (element.type == "date") {
                        element.value = value;
                        element.addEventListener("input", debounce((event) => self.lzSetValue(element.getAttribute("data-lzproperty"), event.target.value)));
                    } else if (element.type == "checkbox") {
                        if (element.getAttribute("role") == "switch") {
                            element.checked = value.toLowerCase() == "true";
                            element.addEventListener("click", (event) => self.lzSetValue(element.getAttribute("data-lzproperty"), event.target.checked));
                        } else {
                            element.checked = value.toLowerCase() == element.value;
                            element.addEventListener("click", (event) => self.lzSetValue(element.getAttribute("data-lzproperty"), event.target.value));
                        }
                    } else if (element.type == "radio") {
                        element.checked = (value == element.value);
                        element.addEventListener("click", (event) => self.lzSetValue(element.getAttribute("data-lzproperty"), event.target.value));
                    } else {
                        element.value = value;
                        element.addEventListener("change", (event) => self.lzSetValue(element.getAttribute("data-lzproperty"), event.target.value));
                    }
                } else if (element.tagName == "BUTTON" && element.getAttribute("role") == "switch") {
                    element.setAttribute("aria-checked", value.toLowerCase() == "true");
                    element.addEventListener("click", (event) => self.lzSetValue(element.getAttribute("data-lzproperty"), !(event.target.getAttribute("aria-checked") == "true")));
                } else if (element.tagName == "SELECT") {
                    element.value = value;
                    //element.addEventListener("click", (event) => self.lzSetValue(element.getAttribute("data-lzproperty"), event.target.value));
                    element.addEventListener("change", (event) => self.lzSetValue(element.getAttribute("data-lzproperty"), event.target.value));
                    element.addEventListener("keydown", (event) => {
                        if (event.key == " ") return;
                        if (event.key == "Enter") return;
                        if (event.key == "Tab") return;
                        event.preventDefault();
                    });
                } else if (element.tagName == "TEXTAREA") {
                    element.value = value;
                    element.addEventListener("input", debounce((event) => { self.lzSetValue(element.getAttribute("data-lzproperty"), event.target.value); }));
                } else {
                    element.value = value;
                    element.addEventListener("change", (event) => { console.log(event.target); console.log(event.target.value); self.lzSetValue(element.getAttribute("data-lzproperty"), event.target.value); });
                }
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzvalue]`).forEach(element => {
            try {
                const value = self.lzEvalString(element.getAttribute("data-lzvalue"));
                if (element.tagName == "INPUT" && (element.type == "text" || element.type == "number" || element.type == "email" || element.type == "password")) {
                    if (element.value != value) element.value = value;
                } else if (element.tagName == "SELECT") {
                    if (element.value != value) element.value = value;
                } else if (element.tagName == "TEXTAREA") {
                    if (element.innerText != value) element.innerText = value;
                } else if (element.tagName == "SPAN") {
                    if (element.innerHTML != value) element.innerHTML = value;
                } else {
                    if (element.value != (value)) element.value = value;
                }
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzchecked]`).forEach(element => {
            try {
                const value = self.lzEval(`return ${element.getAttribute("data-lzchecked")}`)?.toString() || "";
                if (element.tagName == "INPUT" && element.type == "checkbox") {
                    if (element.getAttribute("role") == "switch") {
                        if (element.checked != (value.toLowerCase() == "true")) element.checked = value.toLowerCase() === "true";
                    } else {
                        if (element.checked != (value.toLowerCase() == element.value)) element.checked = value.toLowerCase() == element.value;
                    }
                } else if (element.tagName == "INPUT" && element.type == "radio") {
                    if (element.checked != (value.toLowerCase() == "true")) element.checked = (value.toLowerCase() == "true");
                }
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzhidden]`).forEach(element => {
            try {
                const value = self.lzEvalBoolean(element.getAttribute("data-lzhidden"));
                if (value) {
                    if (!element.classList.contains("hidden")) element.classList.add("hidden");
                } else {
                    if (element.classList.contains("hidden")) element.classList.remove("hidden");
                }
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzdisabled]`).forEach(element => {
            try {
                const value = self.lzEvalBoolean(element.getAttribute("data-lzdisabled"));
                if (element.disabled != value) element.disabled = value;
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzrequired]`).forEach(element => {
            try {
                const value = self.lzEvalBoolean(element.getAttribute("data-lzrequired"));
                if (element.required != value) element.required = value;
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzhref]`).forEach(element => {
            try {
                const value = self.lzEvalString(element.getAttribute("data-lzhref"));
                if (element.href != value) element.href = value;
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzinnerhtml]`).forEach(element => {
            try {
                const value = self.lzEvalString(element.getAttribute("data-lzinnerhtml"));
                if (element.innerHTML != value) element.innerHTML = value;
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzinnertext]`).forEach(element => {
            try {
                const value = self.lzEvalString(element.getAttribute("data-lzinnertext"));
                if (element.innerText != value) element.innerText = value;
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lztitle]`).forEach(element => {
            try {
                const value = self.lzEvalString(element.getAttribute("data-lztitle"));
                if (element.getAttribute("title") != value) element.setAttribute("title", value);
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzarialabel]`).forEach(element => {
            try {
                const value = self.lzEvalString(element.getAttribute("data-lzarialabel"));
                if (element.getAttribute("aria-label") != value) element.setAttribute("aria-label", value);
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzsrc]`).forEach(element => {
            try {
                const value = self.lzEvalString(element.getAttribute("data-lzsrc"));
                if (element.getAttribute("src") != value) element.setAttribute("src", value);
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzdatasrc]`).forEach(element => {
            try {
                const value = self.lzEvalString(element.getAttribute("data-lzdatasrc"));
                if (element.getAttribute("data-src") != value) element.setAttribute("data-src", value);
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzonclick]`).forEach(element => {
            try {
                element.addEventListener("click", (event) => Promise.resolve().then(() => self.lzEval(`const event = arguments[0]; ${element.getAttribute("data-lzonclick")}`, event)));
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzonkeydown]`).forEach(element => {
            try {
                if (element.hasAttribute("data-lzonkeydowndebounce")) {
                    element.addEventListener("keydown", debounce((event) => self.lzEval(`const event = arguments[0]; ${element.getAttribute("data-lzonkeydown")}`, event), parseInt(element.getAttribute("data-lzonkeydowndebounce") || "300")));
                } else {
                    element.addEventListener("keydown", (event) => self.lzEval(`const event = arguments[0]; ${element.getAttribute("data-lzonkeydown")}`, event));
                }
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzonkeyup]`).forEach(element => {
            try {
                if (element.hasAttribute("data-lzonkeyupdebounce")) {
                    element.addEventListener("keyup", debounce((event) => self.lzEval(`const event = arguments[0]; ${element.getAttribute("data-lzonkeyup")}`, event), parseInt(element.getAttribute("data-lzonkeyupdebounce") || "300")));
                } else {
                    element.addEventListener("keyup", (event) => self.lzEval(`const event = arguments[0]; ${element.getAttribute("data-lzonkeyup")}`, event));
                }
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzonmousedown]`).forEach(element => {
            try {
                element.addEventListener("mousedown", (event) => Promise.resolve().then(() => self.lzEval(`const event = arguments[0]; ${element.getAttribute("data-lzonmousedown")}`, event)));
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzonmouseup]`).forEach(element => {
            try {
                element.addEventListener("mouseup", (event) => Promise.resolve().then(() => self.lzEval(`const event = arguments[0]; ${element.getAttribute("data-lzonmouseup")}`, event)));
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzonchange]`).forEach(element => {
            try {
                element.addEventListener("change", (event) => Promise.resolve().then(() => self.lzEval(`const event = arguments[0]; ${element.getAttribute("data-lzonchange")}`, event)));
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzonsubmit]`).forEach(element => {
            try {
                element.addEventListener("submit", (event) => Promise.resolve().then(() => self.lzEval(`const event = arguments[0]; ${element.getAttribute("data-lzonsubmit")}`, event)));
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzmin]`).forEach(element => {
            try {
                element.setAttribute("min", self.lzEvalString(element.getAttribute("data-lzmin")));
            } catch (ex) {
                console.error(ex);
            }
        });

        self.lzGetElements(`[data-lzmax]`).forEach(element => {
            try {
                element.setAttribute("max", self.lzEvalString(element.getAttribute("data-lzmax")));
            } catch (ex) {
                console.error(ex);
            }
        });
    }

    async lzOnInit(params) {
        await this.lzOnRender(params);
        this.lzAddObserver((changes) => {
            const self = this;

            changes.forEach(change => {
                try {
                    this.element.querySelectorAll(`[data-lzproperty^="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const newValue = self.lzGetValue(element.getAttribute("data-lzproperty"));
                            if (element.tagName === "INPUT") {
                                if (element.type === "text" || element.type === "number" || element.type === "email" || element.type === "password") {
                                    if (element.value != (newValue?.toString() || "")) element.value = newValue?.toString() || "";
                                } else if (element.type === "date") {
                                    if (element.value != (newValue?.toString() || "")) element.value = newValue?.toString() || "";
                                } else if (element.type == "checkbox") {
                                    if (element.getAttribute("role") == "switch") {
                                        if (element.checked != (newValue?.toString()?.toLowerCase() === "true")) element.checked = newValue?.toString()?.toLowerCase() == "true";
                                    } else {
                                        if (element.checked != (newValue?.toString()?.toLowerCase() === element.value)) element.checked = newValue?.toString()?.toLowerCase() == element.value;
                                    }
                                } else if (element.type == "radio") {
                                    if (element.checked != (newValue?.toString() == element.value)) element.checked = (newValue?.toString() == element.value);
                                } else {
                                    if (element.value != (newValue?.toString() || "")) element.value = newValue?.toString() || "";
                                }
                            } else if (element.tagName == "BUTTON" && element.getAttribute("role") == "switch") {
                                element.setAttribute("aria-checked", newValue?.toString()?.toLowerCase() == "true");
                            } else if (element.tagName === "SELECT") {
                                if (element.value != (newValue?.toString() || "")) element.value = newValue?.toString() || "";
                            } else if (element.tagName === "TEXTAREA") {
                                if (element.value != (newValue?.toString() || "")) {
                                    element.value = newValue?.toString() || "";
                                    element.dispatchEvent(new Event("change"));
                                }
                            } else {
                                if (element.value != (newValue?.toString() || "")) element.value = newValue?.toString() || "";
                            }
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lzvalue*="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const value = self.lzEvalString(element.getAttribute("data-lzvalue"));
                            if (element.tagName === "INPUT" && (element.type === "text" || element.type === "number" || element.type === "email" || element.type === "password")) {
                                if (element.value != value) element.value = value;
                            } else if (element.tagName === "SELECT") {
                                if (element.value != value) element.value = value;
                            } else if (element.tagName === "TEXTAREA") {
                                if (element.innerText != value) element.innerText = value;
                            } else if (element.tagName == "SPAN") {
                                if (element.innerHTML != value) element.innerHTML = value;
                            } else {
                                if (element.value != value) element.value = value;
                            }
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lzchecked*="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const value = self.lzEval(`return ${element.getAttribute("data-lzchecked")}`)?.toString() || "";
                            if (element.tagName == "INPUT" && element.type == "checkbox") {
                                if (element.getAttribute("role") == "switch") {
                                    if (element.checked != (value.toLowerCase() == "true")) element.checked = value.toLowerCase() === "true";
                                } else {
                                    if (element.checked != (value.toLowerCase() == element.value)) element.checked = value.toLowerCase() == element.value;
                                }
                            } else if (element.tagName == "INPUT" && element.type == "radio") {
                                if (element.checked != (value.toLowerCase() == "true")) element.checked = (value.toLowerCase() == "true");
                            }
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lzhidden*="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const value = self.lzEvalBoolean(element.getAttribute("data-lzhidden"));
                            if (value) {
                                if (!element.classList.contains("hidden")) element.classList.add("hidden");
                            } else {
                                if (element.classList.contains("hidden")) element.classList.remove("hidden");
                            }
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lzdisabled*="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const value = self.lzEvalBoolean(element.getAttribute("data-lzdisabled"));
                            if (element.disabled != value) element.disabled = value;
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lzrequired*="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const value = self.lzEvalBoolean(element.getAttribute("data-lzrequired"));
                            if (element.required != value) element.required = value;
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lzhref*="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const value = self.lzEvalString(element.getAttribute("data-lzhref"));
                            if (element.href != value) element.href = value;
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lzinnerhtml*="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const value = self.lzEvalString(element.getAttribute("data-lzinnerhtml"));
                            if (element.innerHTML != value) element.innerHTML = value;
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lzinnertext*="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const value = self.lzEvalString(element.getAttribute("data-lzinnertext"));
                            if (element.innerText != value) element.innerText = value;
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lztitle*="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const value = self.lzEvalString(element.getAttribute("data-lztitle"));
                            if (element.getAttribute("title") != value) element.setAttribute("title", value);
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lzarialabel*="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const value = self.lzEvalString(element.getAttribute("data-lzarialabel"));
                            if (element.getAttribute("aria-label") != value) element.setAttribute("aria-label", value);
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lzsrc*="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const value = self.lzEvalString(element.getAttribute("data-lzsrc"));
                            if (element.getAttribute("src") != value) element.setAttribute("src", value);
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lzdatasrc*="${change.currentPath}"]`).forEach((element) => {
                        try {
                            const value = self.lzEvalString(element.getAttribute("data-lzdatasrc"));
                            if (element.getAttribute("data-src") != value) element.setAttribute("data-src", value);
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    this.element.querySelectorAll(`[data-lzmin*="${change.currentPath}"]`).forEach(element => {
                        try {
                            const value = self.lzEvalString(element.getAttribute("data-lzmin"));
                            if (element.getAttribute("min") != value) element.setAttribute("min", value);
                        } catch (ex) {
                            console.error(ex);
                        }
                    });
            
                    this.element.querySelectorAll(`[data-lzmax*="${change.currentPath}"]`).forEach(element => {
                        try {
                            const value = self.lzEvalString(element.getAttribute("data-lzmax"));
                            if (element.getAttribute("max") != value) element.setAttribute("max", value);
                        } catch (ex) {
                            console.error(ex);
                        }
                    });

                    if (typeof this.lzOnDataChange === "function") this.lzOnDataChange(change);
                } catch (ex) {
                    console.error(ex);
                }
            });
        });
    }

    lzOnDataChange(change) { }

    dispose() {
        this.dispatchEvent(new Event("lzspa:component.dispose", { cancelable: false, bubbles: false }));
        this.element.remove();
        return true;
    }
    
    reload() {
        return true;
    }
}

const LZSPARouter = new class extends EventTarget {
    #debug = true;
    #element = null;
    #routes = [];
    #activeComponent;
    #documentTitleExp = window._lzDocumentTitleExp || document.title;

    initialize(opts = {}) {
        
        for (let route of opts.routes || []) {
            if (route.path === undefined)
                throw new Error("route missing path");
            if (route.component === undefined)
                throw new Error("route missing component");
            if (typeof route.canActivate !== "undefined" && typeof route.canActivate !== "function")
                throw new Error("canActivate must be a function");
            if (typeof route.canActivate === "undefined")
                route.canActivate = () => true;
            this.#routes.push({
                path: route.path,
                component: route.component,
                canActivate: route.canActivate
            });
            this.log(`Added route: ${route.path}`);
        }
        if (opts.element !== undefined)
            this.#element = opts.element;
        else
            this.#element = document.body;
        if (opts.debug !== undefined)
            this.#debug = opts.debug.toString().toLowerCase() == "true";
        window.addEventListener("popstate", (event) => this.navigate(location.href));

        if (opts.disablelinktrap?.toString().toLowerCase() != "true") {
            document.body.addEventListener("click", (event) => {
                if (event.target.tagName !== "A") { return; }
                if ((event.target.getAttribute("href") || "").startsWith("//") == true) { return; }
                if ((event.target.getAttribute("target") || "_self") != "_self") { return; }
                if ((event.target.getAttribute("href") || "").replace(location.origin, "").startsWith("/") == false) { return; }
                event.preventDefault();
                this.navigate(event.target.getAttribute("href"));
            });
        }
        this.navigate(location.href);
        return this;
    }
    
    get debug() { return this.#debug; }
    set debug(value) { this.#debug = value; }
    get element() { return this.#element; }
    set element(value) { this.#element = value; }
    get routes() { return this.#routes; }
    set routes(value) { this.#routes = value; }
    get activeComponent() { return this.#activeComponent; }
    set activeComponent(value) { this.#activeComponent = value; }

    getDocumentTitle = new Function("title", `"use strict"; return \`${this.#documentTitleExp}\`;`);

    on(type, callback, options) {
        this.addEventListener(type, callback, options);
        return this;
    }

    log(message) {
        if (this.#debug)
            console.info(`LZSPA: ${message}`);
    }

    async navigate(url) {
        try {
            this.dispatchEvent(new CustomEvent("lzspa:router.start", { detail: { url: url } }));
            const pathname = new URL(url, location.origin).pathname;
            if (this.#activeComponent !== undefined) {
                if ((await this.#activeComponent.dispose()) == false)
                    return false;
                this.#activeComponent = undefined;
            }
            const result = this.#routes.find(route => pathname.match(new RegExp("^" + route.path.replace(/\//g, "\\/").replace(/:\w+/g, "(.+)") + "$")) !== null);
            if (result === undefined) {
                this.dispatchEvent(new CustomEvent("lzspa:router.notfound", { detail: { url: url } }));
                return false;
            }

            var paramKeys = Array.from(result.path.matchAll(/:(\w+)/g)).map(result => result[1]);
            var paramValues = (pathname.match(new RegExp("^" + result.path.replace(/\//g, "\\/").replace(/:\w+/g, "(.+)") + "$")) || []).splice(1);
            var params = Object.fromEntries(paramKeys.map((key, i) => [key, paramValues[i]]));

            if (!(await result.canActivate(params))) {
                this.dispatchEvent(new CustomEvent("lzspa:router.accessdenied", { detail: { url: url } }));
                return false;
            }

            history.pushState(null, null, url);
            //while(this.#element.firstChild && this.#element.removeChild(this.#element.firstChild));
            this.#activeComponent = new (await result.component()).default(params).initialize(params);
            this.#element.appendChild(this.#activeComponent.element);
            //await this.#activeComponent.lzOnInit(params);
            //await this.#activeComponent.initialize(params);
            return true;
        } catch (ex) {
            console.error(ex);
            return false;
        } finally {
            this.dispatchEvent(new CustomEvent("lzspa:router.end", { detail: { url: url } }));
        }
    }
}

export {
    LZSPAComponent,
    LZSPARouter,
    LZSPAObserver,
    LZSPACompile,
    handlebars
};
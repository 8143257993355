export function showToast(message, config = {}) {
    var toastContainerElement = document.getElementById("toastContainer");
    var element = document.createElement("div");
    element.className = config.className || "";
    element.role = "alert";
    element.ariaLive = "polite";
    element.ariaAtomic = "true";
    element.innerHTML = message;
    element.addEventListener("click", (event) => { event.target.remove(); })
    setTimeout(() => { element.remove(); }, config.timeout || 3000);
    toastContainerElement.appendChild(element);
}

export function showSuccessToast(message) {
    showToast(message, { className: "success" });
}

export function showInfoToast(message) {
    showToast(message, { className: "info" });
}

export function showErrorToast(message) {
    showToast(message, { className: "error" });
}